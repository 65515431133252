import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`You have the option of adding tabs to your pages. This is only recommended if you have several pages that will use the same tabs. If you only have one page with a set of tabs, it might be better for discoverability to change the tabs to menu items.`}</p>
    </PageDescription>
    <h2>{`Tabs in YAML`}</h2>
    <p>{`To create a tabbed page, you just need to point the theme to the path of your first tab. This is the structure of the YAML configuration for this page and it’s siblings.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`- title: Guides
  pages:
    - title: Configuration
      path: /guides/configuration
    - title: Shadowing
      path: /guides/shadowing
    - title: Styling
      path: /guides/styling
    - title: Navigation
      path: /guides/navigation/sidebar
`}</code></pre>
    <h2>{`File structure`}</h2>
    <p>{`Let’s check out the directory structure for this page. Notice how you’ll add another directory that corresponds with the one in the YAML file.`}</p>
    <pre><code parentName="pre" {...{}}>{`.
├── pages
│   └── guides
│       ├── configuration.mdx
│       ├── shadowing.mdx
│       ├── styling.mdx
│       └── navigation
│           ├── tabs.mdx
│           └── sidebar.mdx
`}</code></pre>
    <h2>{`Markdown updates`}</h2>
    <p>{`The last step is to add the name of your tabs to the front matter of each markdown file that has tabs.`}</p>
    <p><strong parentName="p">{`Known issue`}</strong>{`: at the moment, your tab name needs to match your title for the navigation components to function properly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`---
title: Sidebar
tabs: ['Sidebar', 'Tabs']
---
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      